
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later
@import "variables";

// Bootstrap library
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "ladder";

@import "../components/custom/section.scss";
@import "../components/custom/building_menu.scss";


.home-menu {
    /* this is just to position the home menu over the ladder animation. */
    z-index: 1900;
    @include media-breakpoint-down(lg) {
        padding-top:180px;
    }
    @include media-breakpoint-down(md) {
        padding-top:200px;
    }
    @include media-breakpoint-down(sm) {
        padding-top:250px;
    }
}
/*
This could be useful to allow the text to be more readable on the background image 
on the home screen, but it's a bit of a kludge.
.home-content {
    background-color: rgba(0,0,0,0.25);
}
*/
header {
    z-index: 2000;
}
/* This displays the menu text over the background SVG images */
.main-menu li a {
    position: relative;
    z-index: 10;
}
a {
    font-weight: $lead-font-weight;
}
img {    
    max-width: 100%; /* prevent images being displayed outside their containers */
    height:auto !important; /* this is to prevent images from being stretched */
}

.alerts {
    /* display alerts on top of ladder. */
    z-index: 10000;
    position: relative;
}