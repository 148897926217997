.main-menu {
    position: relative;
    background:white;
    color:black;
    li {
        margin:3px;
        background-color: grey;
        .bg-image {
            mix-blend-mode: multiply;
            pointer-events: none;
        }
        a {
            display: block;
            padding: 0 3rem;
            @include media-breakpoint-down(sm) {
                padding: 0 1.5rem;
            }
            @include media-breakpoint-down(xs) {
                padding: 0 1rem;
            }
        }
    }
    .ladder-container {
        position: absolute;
        height: auto;
        right:0;
        width: 19%;
        aspect-ratio: 0.20;
    }
}