.section-content {
    position: relative;
    z-index: 100;
}
section {
    padding-right:20vw;
    @include media-breakpoint-up(md) {
        padding-right:15vw;
    }
    @include media-breakpoint-up(lg) {
        padding-right:10vw;
        height: 100vh;
    }
    .bg-image {
        mix-blend-mode: multiply;
        pointer-events: none;
        background-size: auto 100%;
        background-position: center;
    }
}
.section-scroller {
    .stage {
        overflow: hidden;
    }
    .section-item {
        padding: 1%;
        max-width: 600px;
        @include media-breakpoint-up(md) {
            padding:5%;
        }
    }
    
    .scroller-button {
        --#{$prefix}gutter-x: #{$grid-gutter-width};
        position: absolute;
        top:0;
        bottom:0;
        width:2%;
        background-color: transparent;
        border:none;
        background-image: url('../assets/next.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        &.next {
            right:calc(calc(-0.5 * var(--bs-gutter-x)) - 2%);
        }
        &.previous {
            left:calc(calc(-0.5 * var(--bs-gutter-x)) - 2%);
            transform: rotate(180deg);
        }
    }
}