// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables
// node_modules/bootstrap/scss/_variables.scss


// Theme colors
$primary: goldenrod;
$secondary: #86888A;
// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Fonts
$primary-text-emphasis: #fff;
$body-emphasis-color: #fff;

$font-family-base: 'Montserrat', sans-serif;
$font-size-root: 16px;

// Links
$link-color: #99d8ff;
$link-decoration: none;

// Navbar
$navbar-light-icon-color: #fff;
$border-radius:               0;
$border-radius-sm:            0;
$border-radius-lg:            0;

$btn-border-radius: 50rem;
$btn-border-radius-lg: 50rem;
$btn-border-radius-sm: 50rem;

$lead-font-weight: 600;