// here we style the animated ladder feature.
// It's fixed on the screen and the animation is triggered by the user's scroll.
// It's hidden under the main menu splash screen by z-index
.ladder-container {
    position:fixed;
    right:0;
    bottom:0;
    width:auto;
    aspect-ratio: 0.133;
    height:110vh;
    pointer-events: none;
    z-index:1000;
    .ladder {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-image:url(../assets/ladder.svg);
        background-repeat:no-repeat;
        background-position: right bottom;
    }
    .firefighter {
        .frame {
            position: absolute;
            bottom:0;
            right:52%;
            width:100%;
            height:15%;
            background-image:url(../assets/fireman.svg);
            background-repeat: no-repeat;
            background-position: bottom right;
            display: none;            
            &.frame-2 {
                background-image:url(../assets/fireman2.svg);
            }
            &.frame-3 {
                background-image:url(../assets/fireman3.svg);
            }
        }
        
    }
    &.activate-frame-1 .frame-1 {
        display: block;
    }
    &.activate-frame-2 {
        .ladder {
            transform: translate(-0.5%, 1%);
        }
        .frame-2 {
            display: block;
        }
    }
    &.activate-frame-3 {
        .ladder {
            transform: translate(-1%, 2%);
        }
        .frame-3 {
            display: block;
        }
    }
}